/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.deleted-price {
  font-size: 14px;
  font-weight: 600;
  color: #808080;
}

.custom-btn-warning {
  background-color: #e3181b;
  border: #e3181b;
  padding: 7px;
  color: white;
  /* height: 28px; */
}

.custom-btn-warning:focus {
  border: none;
}

.ul-text {
  color: #656565;
  font-family: poppins;
}

.heading-tetx {
  color: #808080;
}

.para-text {
  color: #808080;
  font-family: "poppins" !important;
}

.checkout-text {
  color: #808080;
  font-family: "poppins";
}

.icon-color {
  color: #000;
}

.banner-custom-card {
  position: relative;
  /* text-align: center; */
  /* padding: 50px 20px 50px; */
  /* background-color: #ffffff; */
  /* border: 1px solid #e3181b !important; */
  box-shadow: 0 0 30px rgb(0 0 0 / 5%);
  transition: all 300ms ease;
}

.icons-bg {
  width: 15px;
  height: 15px;
  padding-left: 3px;
  font-size: 10px;
  border-radius: 20px;
  padding-top: 3px;
  background-color: #e3181b;
}

/* .auto-spinner .spinner{
  position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    background-image: url(http://ary-themes.com/html/bold_touch/fixomiz/images/icons/icon-devider.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    animation: rotate 30s infinite;
    -webkit-animation: rotation 20s infinite 
} */

.get_started:hover {
  background-color: rgb(255 191 0);
}

.auto-spinner .spinner {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  background-image: url(http://ary-themes.com/html/bold_touch/fixomiz/images/icons/icon-devider.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  animation: rotate 0.6s infinite;
  -webkit-animation: rotation 20s infinite
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


/* .auto-spinner .spinner {
  -webkit-animation: rotation 2s infinite linear;
}

@-webkit-keyframes rotation {
  from {
      -webkit-transform: rotate(0deg);
  }
  to {
      -webkit-transform: rotate(359deg);
  }
} */

/* .fixedFooter {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color:#ffffff;
  color: white;
  text-align: center;
} */

* {
  color: #222325;
  font-family: "poppins";
}

.customModal .modal-dialog .modal-content {
  border-radius: 1rem !important;

}


.about-section ul {
  text-align: left;
  list-style: none;
}

.about-section ul.about-right-tool li {
  line-height: 24px;
  padding-bottom: 0px !important;
  font-size: 15px;
  color: #62646a;
  position: relative;
  padding-left: 30px;
}


.about-section ul.about-right-tool li .about-right-count {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  font-weight: 700;
  display: inline;
  margin-top: 0px;
  margin-bottom: 0px;
  min-width: initial;
  position: relative;
  left: -25px;
  top: -42px;
  line-height: 1;
}

.about-section ul li span {
  font-weight: 600;
  /* font-size: 15px; */
  letter-spacing: 1px;
  color: #222326;
  display: block;
  margin-bottom: 6px;
}


.about-section ul.about-right-tool li p {
  font-size: 14px;
  letter-spacing: 1px;
  margin-bottom: 0px;
  margin-left: 50px;
  position: relative;
  top: -50px;
}


.section-process1 {
  font-size: 17px;
  font-weight: bold;
  background-color: #bbe4ff;
  padding: 9px 17px;
  border-radius: 45px;
}

.section-process2 {
  font-size: 17px;
  font-weight: bold;
  background-color: #efbede;
  padding: 9px 15px;
  border-radius: 45px;
}

.section-process3 {
  font-size: 17px;
  font-weight: bold;
  background-color: #bbe4ff;
  padding: 9px 15px;
  border-radius: 45px;
}


.numbersBackground {
  background: #a8bffd;
  width: 50px;
  height: 13px;
  position: absolute;
  /* top: 118px; */
}


.icons-background {
  font-size: 17px;
  font-weight: bold;
  background-color: #bbe4ff73;
  padding: 18x 17px !important;
  border-radius: 45px;
  border-radius: 20px;
  color: #08568f;
}

.cards-aboutus {
  border-radius: 5px;
  border-top-color: coral !important;
  border-radius: 21px;
}


.partnersList {
  background-color: #f0f2f5;
  text-align: center;
  border-radius: 15px;
  margin-bottom: 25px;
  padding: 20px 30px;
  transition: transform .2s;
}

.partnersList:hover {
  transform: translateY(0) scale(1.1);
  box-shadow: 0px 1px 4px 1px rgb(0 0 0 / 15%);
}

.card-inner-box {
  position: relative;
  padding: 50px 30px;
  /* text-align: center; */
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 0px 30px rgb(0 0 0 / 5%);
  /* -webkit-transition: all 500ms ease; */
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.heading-overview {
  color: #e3181b;
  text-transform: uppercase;
  font-size: 16px;
}

.icons-background {
  background-color: #cbb282;
  width: 50px;
  padding-top: 12px;
  height: 50px;
  color: #fff;

}


.active-date-slots {
  background-color: #33b14c;
  font-size: 12px;
  padding: 8px;
  /* font-weight: 600; */

  color: #fff;
  border-radius: 10px;
}

.inactive-date-slots {
  border: 1px solid green !important;
  background-color: #fff;
  font-size: 12px;
  padding: 8px;
  /* font-weight: 600; */
  color: #000;
  border-radius: 10px;
}


.active-time-slots {
  background-color: #33b14c;
  font-size: 12px;
  padding: 8px;
  /* font-weight: 600; */
  color: #fff;
  border-radius: 10px;
}

.inactive-time-slots {
  border: 1px solid green !important;
  background-color: #fff;
  font-size: 12px;
  padding: 8px;
  /* font-weight: 600; */
  color: #000;
  border-radius: 10px;
}

.cursor-pointer {
  cursor: pointer;
}

.slots-text {
  font-size: 14px;
  /* color: #000; */
  /* font-weight: 600; */
}

@media only screen and (min-device-width : 300px) and (max-device-width : 768px) {
  .schedule-pick-up-btn {
    position: fixed;
    bottom: 0%;
    right: 0%;
    z-index: 3;
  }
}